<template>
  <component
    :is="href ? 'a' : to ? 'router-link' : 'button'"
    v-bind="linkProps"
    :disabled="loading || disabled"
    @click="$emit('click')"
    @click.self="$emit('self')"
    class="icon-button flex flex--center flex--align flex--no-shrink"
  >
    <to-icon v-if="loading" name="loading" />
    <slot v-else />
    <span v-if="tooltip" class="icon-button__tooltip">{{ tooltip }}</span>
  </component>
</template>

<script>
export default {
  name: 'IconButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    to: {
      default: null,
    },
    href: {
      type: String,
      default: null
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
  computed: {
    linkProps() {
      if (!this.href && !this.to) return;
      return !!this.href
        ? { href: this.href, target: '_blank' }
        : { to: this.to };
    },
  }
};
</script>

<style lang="scss">
.icon-button {
  background-color: transparent;
  color: var(--text-color);
  width: 28px;
  height: 28px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  * {
    pointer-events: none;
  }

  &.secondary {
    background-color: var(--text-color);
    color: var(--text-color--inverted);
  }

  &.primary {
    background-color: var(--primary);
    color: white;

    @include desktop-hover {
      background-color: var(--primary-hover) !important;
      color: white !important;
    }
  }

  &.clear {
    background-color: transparent;
    color: var(--text-color);

    @include desktop-hover {
      background-color: var(--light-highlight);
    }
  }

  &.grey {
    background-color: var(--light-highlight);
    color: var(--text-color);

    @include desktop-hover {
      background-color: rgba(var(--primary), .1);
    }
  }

  &.alert {
    background-color: #e74c3c;
    color: white;

    @include desktop-hover {
      background-color: #c0392b !important;
      color: white !important;
    }
  }

  @include desktop-hover {
    &:not(:disabled) {
      @include mode(background-color, rgba(white, .1), var(--light-highlight));
      padding: 0px;
      @include mode(color, var(--text-color), var(--primary));
    }
  }

  &:disabled {
    opacity: .3;
    cursor: not-allowed;
  }

  .app-icon {
    width: 100%;
    height: 100%;
  }

  &:hover &__tooltip {
    display: block;
    animation: .25s tooltip-hover linear forwards;
    animation-delay: .25s;

    @keyframes tooltip-hover {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }

  &__tooltip {
    display: none;
    position: absolute;
    top: calc(100% + 5px);
    left: 50%;
    z-index: 999;
    transform: translateX(-50%);
    background-color: var(--text-color--inverted);
    color: var(--text-color);
    padding: 2px 5px;
    border-radius: 100px;
    pointer-events: none;
    opacity: 0;
    white-space: nowrap;
  }
}
</style>