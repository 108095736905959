<template>
  <div class="auth-wrapper">
    <!-- Left Section -->
    <div :class="['auth-wrapper__left', { 'full-width': hideRight }]">
      <div class="auth-wrapper__header flex flex--align flex--sb">
        <a href="https://taskorbiter.com">
          <to-icon name="taskorbiter" />
        </a>
        <div v-if="$slots.header || showLogOut" class="auth-wrapper__header-right">
          <slot name="header" />
          <to-text-icon-button
            v-if="showLogOut"
            text="Log Out"
            class="sz-2 ml-2"
            button-style="tertiary"
            @click="logOut()"
          />
        </div>
      </div>
      <div :style="{ maxWidth: maxWidth }" class="auth-wrapper__left-content">
        <slot name="title" />
        <slot name="sub-title" />
        <div class="auth-wrapper__content">
          <slot />
        </div>
        <slot name="bottom" />
      </div>
      <div v-if="$slots.footer" class="auth-wrapper__footer pb-2">
        <slot name="footer" />
      </div>
    </div>

    <!-- Illustration Section -->
    <div v-if="!hideRight" class="auth-wrapper__right">
      <div class="auth-wrapper__right-content">
        <div class="auth-wrapper__quote">A convenient place for me to organize everything from project deliverable deadlines, to action items.</div>
        <div class="auth-wrapper__name">— Alex P. </div>
      </div>
      <img :src="require(isDarkMode ? '@/assets/images/flow-illustration-dark-small.png' : '@/assets/images/flow-illustration.png').default" alt="Screenshot of TaskOrbiter" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthWrapper',
  props: {
    maxWidth: {
      type: String,
      default: '450px'
    },
    hideLogOut: {
      type: Boolean,
      default: false
    },
    hideRight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showLogOut() {
      return this.$store.state.isLoggedIn && !this.hideLogOut;
    },
    isDarkMode() {
      return this.$store.state.darkMode;
    }
  },
  mounted() {
    this.focusFirstInput();
  },
  methods: {
    async focusFirstInput() {
      await this.$nextTick();
      const inputs = this.$el.querySelectorAll('input[type=\'email\'], input[type=\'text\']');
      if (inputs && inputs.length) inputs[0].focus();
    },
    logOut() {
      this.$store.dispatch('logOut');
      this.$router.push('/login').catch(() => {});
    }
  }
};
</script>

<style lang="scss">
$mobile-bp: 1000px;

.auth-wrapper {
  display: flex;
  height: 100vh;
  background-color: var(--foreground-color);
  overflow-y: auto;

  &__left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    padding-top: 0;
    align-items: center;
    height: fit-content;
    min-height: 100%;

    & > * {
      min-height: 0;
    }

    &-content {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      padding: 10px;
      width: 100%;
      flex-grow: 1;

      @media screen and (max-width: $mobile-bp) {
        align-items: center;
      }
    }

    &.full-width {
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    @media screen and (max-width: $mobile-bp) {
      width: 100%; 
    }
  }

  &__right {
    position: sticky;
    top: 0;
    width: 50%;
    padding-left: 8vw;
    padding-top: 5vw;
    background: linear-gradient(25deg, rgba(255, 203, 110, 0.40) 8.67%, rgba(41, 126, 255, 0.40) 45.1%, rgba(41, 126, 255, 0.08) 92.96%);
    font-weight: 500;
    text-align: left;
    overflow: hidden;

    &-content {
      padding-right: min(122px, 8vw);
      max-width: 640px;
    }

    @media screen and (max-width: $mobile-bp) {
      display: none; 
    }

    img {
      height: 75vh;
      margin-top: min(77px, 5vw);
      border-radius: .75vw;
      @include mode(box-shadow, 0 7px 22px rgba(black, 0.5), 0 7px 22px rgba(black, 0.3));
    }
  }

  &__header {
    @include mode(background-color, rgba(#161920, .95), rgba(white, .8));
    z-index: 999;
    position: sticky;
    top: 0;
    width: 100%;
    padding: 20px;
    flex-shrink: 0;

    a {
      cursor: pointer;
    }

    svg {
      height: 28px;
      width: auto;
      color: var(--text-color);
    }

    &-right {
      display: flex;
      align-items: center;
    }
  }

  &__content {
    width: 100%;
    z-index: 5;

    .is-mobile & {
      padding: 0 15px;
      margin-bottom: 20px;
    }

    .text-icon-button {
      width: 100%;
      font-size: 15px;
      font-weight: 600;
    }
  }

  &__quote {
    font-size: min(27px, 1.75vw);
  }

  &__name {
    opacity: .5;
    margin-top: min(31px, 2vw);
    font-size: min(16px, 1vw);
  }

  &__footer {
    width: 100%;
    max-width: 450px;
    padding: 10px;
    text-align: center;
  }

  .input input {
    @include mode(background-color, var(--light-highlight), var(--background-color));
  }
}
</style>
