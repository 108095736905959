<template>
    <component
        :is="href ? 'a' : to ? 'router-link' : 'button'"
        :to="to"
        :href="href"
        class="text-icon-button"
        :disabled="isLoading || isDisabled"
        :class="[
            buttonStyle,
            { 'is-loading' : isLoading }
        ]"
        @click="$emit('click')"
    >
        <to-icon v-if="isLoading" name="loading" class="text-icon-button__loading" />
        <to-icon v-if="icon" :name="icon" class="flex--no-shrink" />
        <slot v-else />
        <div v-if="description" class="inline-flex flex--column flex--center flex--align">
            <span>{{ text }}</span>
            <span class="text-icon-button__description sz-2">{{ description }}</span>
        </div>
        <span v-else-if="text" class="nowrap">{{ text }}</span>
        <to-icon v-if="rightIcon" :name="rightIcon" class="flex--no-shrink sz-2" />
        <span v-if="tooltip" class="text-icon-button__tooltip">{{ tooltip }}</span>
    </component>
</template>

<script>
export default {
    name: 'TextIconButton',
    props: {
        icon: {
            type: String,
            default: null
        },
        rightIcon: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        buttonStyle: {
            type: String,
            default: 'primary'
        },
        to: {
            default: null
        },
        href: {
            type: String,
            default: null
        },
        tooltip: {
            type: String,
            default: null
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
.text-icon-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: .75em 1em;
    gap: .5em;
    cursor: pointer;
    font-weight: 600;

    & > * {
        pointer-events: none;
    }
    
    &,
    &:hover {
        text-decoration: none !important;
    }

    &.is-loading > *:not(.icon-loading) {
        opacity: 0;
    }

    &.primary {
        background-color: var(--primary);
        color: white;
        
        span {
            color: white;
        }

        @include desktop-hover {
            background-color: var(--primary-hover);
        }
    }

    &.secondary {
        background-color: none !important;
        color: var(--text-color) !important;

        @include desktop-hover {
            background-color: var(--light-highlight) !important;
            @include mode(color, var(--text-color), var(--primary));
        }
    }

    &.bordered {
        background-color: none !important;
        color: inherit;
        border: 1px solid var(--light-border);

        @include desktop-hover {
            background-color: var(--light-highlight) !important;
            color: var(--text-color) !important;
        }
    }

    &.background {
        background-color: var(--background-color) !important;
        color: var(--text-color);
        border: 1px solid var(--light-border);

        @include desktop-hover {
            background-color: var(--light-highlight) !important;
            color: var(--text-color) !important;
        }
    }

    &.tertiary.tertiary {
        background-color: var(--light-highlight);
        color: inherit;

        @include desktop-hover {
            background-color: var(--light-border);
            border-color: rgba(var(--primary), .5);
            color: var(--text-color);
        }
    }

    &.no-background{
        background-color: none;
        color: inherit;
    }

    &.selected {
        background-color: #F5F9FF !important;
        color: var(--primary) !important;
        opacity: 1 !important;
    }

    &.blue-border {
        background-color: none !important;
        color: var(--primary);
        border: 1px solid var(--primary);

        @include desktop-hover {
            background-color: var(--primary-hover) !important;
            color: white;
        } 
    }

    &.white-border {
        background-color: transparent !important;
        color: white;
        border: 1px solid white;

        @include desktop-hover {
            background-color: rgba(white, .25) !important;
        } 
    }

    &.alert-with-text {
        background-color: none !important;
        color: #e74c3c !important;

        @include desktop-hover {
            background-color: var(--light-border) !important;
        }

    }

    &.end {
        background-color: #e74c3c;
        color: white;

        @include desktop-hover {
            background-color: #bd3d2e;
        }
    }

    &.alert {
        background-color: transparent;
        border: 1px solid #e74c3c;
        color: #e74c3c;

        @include desktop-hover {
            background-color: #d54535;
            color: white;
        }
    }

    &.dark.dark {
        @include mode(background-color, var(--primary), var(--text-color));
        color: white;

        @include desktop-hover {
            background-color: var(--primary-hover);
            color: white;
        }
    }

    &.dark-outline.dark-outline {
        background-color: transparent;
        box-shadow: 0 0 0 1px var(--text-color) inset;
        color: var(--text-color);

        @include desktop-hover {
            background-color: var(--primary-hover);
            box-shadow: none;
            color: white;
        }
    }


    &.clear.clear {
        background-color: transparent;

        @include desktop-hover {
            background-color: var(--light-border);
            color: var(--primary);
            @include mode(color, var(--text-color), var(--primary));
        }
    }

    &.upsell {
        background-color: #f1c40f;
        color: var(--text-color);

        @include desktop-hover {
            background-color: #c09c0d;
        }
    }

    &:disabled {
        opacity: .5;
        cursor: default;
        pointer-events: none;
    }

    &:hover &__tooltip {
        display: block;
        animation: .25s tooltip-hover linear forwards;
        animation-delay: .25s;

        @keyframes tooltip-hover {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }
    }

    &__tooltip {
        display: none;
        position: absolute;
        top: calc(100% + 5px);
        left: 50%;
        z-index: 999;
        transform: translateX(-50%);
        background-color: var(--text-color);
        color: var(--text-color--inverted);
        border: 1px solid #646464;
        padding: 2px 5px;
        border-radius: 100px;
        pointer-events: none;
        opacity: 0;
        white-space: nowrap;
    }

    &__description {
        font-weight: 400;
    }

    &__loading {
        position: absolute;
        width: 1.25em;
        transform: translate(-50%, -50%);
    }
}
</style>